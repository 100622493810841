import React from "react";
import Router from "./jsx/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/plugins.css";
import "./css/style.css";
import "./css/templete.css";

function App() {
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
