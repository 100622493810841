import React, { Component } from 'react';
import Maintenance from './pages/maintenance';

class Router extends Component {
  render() {
    return (
      <>
        <Maintenance />
      </>
    );
  }
}

export default Router;
