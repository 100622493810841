import React, { Component } from "react";
import "../../css/skin/skin-11.css";

class Maintenance extends Component {
  componentDidMount() {
    // window.Frost.init(process.env.REACT_APP_LAUNCH_TIME);
    // window.Frost.load();
    // window.handleSideBarMenu();

    if (
      process.env.REACT_APP_MODE === "COMING_SOON" &&
      process.env.REACT_APP_LAUNCH_TIME
    ) {
      window.Frost.init(process.env.REACT_APP_LAUNCH_TIME);
      window.Frost.load();
    }
  }

  render() {
    const countdownRender = () => {
      return (
        <div className="countdown count-1">
          <div
            className="date wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="1.0s"
          >
            <span className="days time"></span>
            <span>Days</span>
          </div>
          <div
            className="date wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="1.3s"
          >
            <span className="hours time"></span>
            <span>Hours</span>
          </div>
          <div
            className="date wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="1.6s"
          >
            <span className="mins time"></span>
            <span>Minutes</span>
          </div>
          <div
            className="date wow fadeInUp"
            data-wow-duration="1.5s"
            data-wow-delay="1.9s"
          >
            <span className="secs time"></span>
            <span>Second</span>
          </div>
        </div>
      );
    };

    return (
      <>
        <div
          className="dez-coming-soon style-7 wow fadeIn"
          data-wow-duration="0.80s"
          data-wow-delay="0.50s"
        >
          <canvas id="canvas3"></canvas>
          <div className="clearfix dez-coming-bx">
            <div className="dez-content top-center posi-center">
              <div
                className="logo wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="1.0s"
              >
                <img
                  src="images/logo-white.png"
                  alt=""
                  style={{ width: "250px" }}
                />
              </div>
              {process.env.REACT_APP_MODE === "COMING_SOON" ? (
                <h2
                  className="dez-title ml2 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="1.5s"
                >
                  Coming Soon
                </h2>
              ) : (
                <h2
                  className="dez-title ml2 wow fadeInUp"
                  data-wow-duration="1.5s"
                  data-wow-delay="1.5s"
                >
                  Under Maintenance
                </h2>
              )}

              <p
                className="wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="1.7s"
              >
                Please join our official community to stay updated.
              </p>
              {process.env.REACT_APP_MODE === "COMING_SOON" &&
                process.env.REACT_APP_LAUNCH_TIME &&
                countdownRender()}
              <div
                className="dez-coming-btn wow fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="2.2s"
              >
                <a
                  href="https://t.me/zeedexio"
                  className="site-button gradient openbtn button-lg radius-md m-r10"
                >
                  <i className="fa fa-paper-plane-o" /> Join Telegram
                </a>
                <a
                  href="https://twitter.com/zeedexio"
                  //   data-toggle="modal"
                  //   data-target="#exampleModal"
                  className="site-button button-lg outline radius-md"
                >
                  <i className="fa fa-twitter" /> Follow Twitter
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Maintenance;
